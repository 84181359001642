// Generated by Framer (f4b44ab)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {VinJ8ZvF0: {hover: true}};

const cycleOrder = ["VinJ8ZvF0"];

const serializationHash = "framer-gDR4S"

const variantClassNames = {VinJ8ZvF0: "framer-v-zk3bpl"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({category, height, id, link, width, ...props}) => { return {...props, kFJtDNWsq: category ?? props.kFJtDNWsq ?? "Latest", mSSMRQqXQ: link ?? props.mSSMRQqXQ} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;category?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, mSSMRQqXQ, kFJtDNWsq, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "VinJ8ZvF0", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-gDR4S", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={mSSMRQqXQ} openInNewTab={false}><motion.a {...restProps} className={`${cx("framer-zk3bpl", className)} framer-c0l5yk`} data-framer-name={"v1"} layoutDependency={layoutDependency} layoutId={"VinJ8ZvF0"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-9d4e2f6b-a2b9-4c9d-a1b7-a140ee1fc1d9, rgb(250, 250, 250))", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}} variants={{"VinJ8ZvF0-hover": {backgroundColor: "var(--token-b343876e-c5ad-4b88-a31d-69399d409c3f, rgb(230, 230, 230))"}}} {...addPropertyOverrides({"VinJ8ZvF0-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SUJNIFBsZXggU2Fucy1yZWd1bGFy", "--framer-font-family": "\"IBM Plex Sans\", sans-serif", "--framer-font-size": "14px", "--framer-line-height": "1.5em", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-6f286720-c046-4b1a-ae36-29b3719ec712, rgb(41, 41, 41)))"}}>Apps</motion.p></React.Fragment>} className={"framer-1q1who3"} data-framer-name={"Category"} fonts={["GF;IBM Plex Sans-regular"]} layoutDependency={layoutDependency} layoutId={"khD7WlKlX"} style={{"--extracted-r6o4lv": "var(--token-6f286720-c046-4b1a-ae36-29b3719ec712, rgb(41, 41, 41))", "--framer-paragraph-spacing": "0px"}} text={kFJtDNWsq} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-gDR4S [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-gDR4S .framer-c0l5yk { display: block; }", ".framer-gDR4S .framer-zk3bpl { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: visible; padding: 7px 14px 7px 14px; position: relative; text-decoration: none; width: min-content; }", ".framer-gDR4S .framer-1q1who3 { -webkit-user-select: none; flex: none; height: auto; position: relative; user-select: none; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-gDR4S .framer-zk3bpl { gap: 0px; } .framer-gDR4S .framer-zk3bpl > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-gDR4S .framer-zk3bpl > :first-child { margin-left: 0px; } .framer-gDR4S .framer-zk3bpl > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 35
 * @framerIntrinsicWidth 67
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"TioSaUl9Q":{"layout":["auto","auto"]}}}
 * @framerVariables {"mSSMRQqXQ":"link","kFJtDNWsq":"category"}
 * @framerImmutableVariables true
 */
const FramerP0YJyuZoH: React.ComponentType<Props> = withCSS(Component, css, "framer-gDR4S") as typeof Component;
export default FramerP0YJyuZoH;

FramerP0YJyuZoH.displayName = "Chip";

FramerP0YJyuZoH.defaultProps = {height: 35, width: 67};

addPropertyControls(FramerP0YJyuZoH, {mSSMRQqXQ: {title: "Link", type: ControlType.Link}, kFJtDNWsq: {defaultValue: "Latest", title: "Category", type: ControlType.String}} as any)

addFonts(FramerP0YJyuZoH, [{family: "IBM Plex Sans", style: "normal", url: "https://fonts.gstatic.com/s/ibmplexsans/v19/zYXgKVElMYYaJe8bpLHnCwDKtdPUFI5NadY.woff2", weight: "400"}])